import React from 'react'
import { networkNames } from './chain'
import { useChain } from './hooks/useChain'

const ConnectOrChildren: React.FC = ({ children }) => {
  const { chain, connected } = useChain()

  const { network } = chain

  console.log('network: ', network, 'connected: ', connected)

  if (!connected || !network) {
    return (
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
        onClick={chain.connect.bind(chain)}
      >
        connect to {networkNames[chain.expectedChain()]}
      </button>
    )
  }

  if (network.chainId !== chain.expectedChain()) {
    return (
      <div>
        <p>Please connect to {networkNames[chain.expectedChain()]}</p>
        <p>You connected to chain id: {network.chainId} ({networkNames[network.chainId]})</p>
      </div>
    )
  }

  return <div>{children}</div>
}

const Layout: React.FC = ({ children }) => {
  return (
    <div>
      <nav className="bg-gray-800" />

      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Fonticulus: Crypto Colosseum Liquidity Incentivizer
          </h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <ConnectOrChildren>
            { children }
          </ConnectOrChildren>
        </div>
      </main>
    </div>
  )
}

export default Layout
