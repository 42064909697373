import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Chain from '../chain'
import useNetworkName from './useNetworkName'

export interface ChainContextData {
  chain: Chain,
  connected: boolean
}

const defaultChain = new Chain('matic')

export const ChainContext = createContext<ChainContextData>({ chain: defaultChain, connected: false})

export const useChain = () => {
  return useContext(ChainContext)
}

export const ChainProvider: React.FC = ({ children }) => {
  const networkName = useNetworkName()

  const [connected, setConnected] = useState(false)

  const chain = useMemo(() => {
    return new Chain(networkName)
  }, [networkName])

  useEffect(() => {
    const handler = () => {
      console.log('setting connected')
      setConnected(chain.connected)
    }
    chain.on('connect', handler)
    return () => {
      chain.off('connect', handler)
    }
  }, [chain.contracts, chain])

  return <ChainContext.Provider value={{chain, connected}}>{children}</ChainContext.Provider>
}
