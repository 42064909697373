import { BigNumber, constants, utils } from 'ethers'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import { useChain } from '../hooks/useChain'
import { useFonticulus } from '../hooks/useFonticulus'
import { useMaticBalance } from '../hooks/useMaticBalance'
import useTokenValue from '../hooks/useTokenValue'
import humanBigNumber from '../humanBigNumber'
import PTGFarm from '../components/PTGFarm'

interface StakeFormData {
  amountMatic: string
}

function LiquidityPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors
  } = useForm<StakeFormData>()

  const { chain } = useChain()
  const { address, network } = chain
  const { data, stake } = useFonticulus(chain)
  const { balance } = useMaticBalance(chain)
  const { values } = useTokenValue(chain, data?.userTokens)
  const { values: oneValues } = useTokenValue(chain, data?.fonticulusOneTokens)
  const [staking, setStaking] = useState(false)

  console.log('values: ', values)

  if (!network) {
    throw new Error('network undefined')
  }

  const onSubmit = async ({ amountMatic }: StakeFormData) => {
    try {
      clearErrors()
      if (!data) {
        throw new Error('no data')
      }
      setStaking(true)
      console.log(data)

      const maticBn = utils.parseEther(amountMatic)

      const expectedAmount = maticBn
        .mul(data.matchPercent)
        .div(100)
        .mul(data.price)
        .div(constants.WeiPerEther)
        .mul(95)
        .div(100)

      const tx = await stake(
        maticBn,
        expectedAmount,
        BigNumber.from(Math.floor(new Date().getTime() / 1000 + 100))
      )
      console.log(tx)
      console.log(await tx.wait())
    } catch (err) {
      setError('amountMatic', {
        message: `Error submitting: ${err.message} (usually means not enough reward or not enough matic)`
      })
    } finally {
      setStaking(false)
    }
  }

  if (!data || staking) {
    return <p>Loading...</p>
  }

  const parsedAmount = utils.parseEther(watch('amountMatic') || '0')

  const amountReceived = parsedAmount
    .mul(data.matchPercent)
    .div(100)
    .mul(data.price)
    .div(constants.WeiPerEther)

  console.log('match/buy percent: ', data.matchPercent, data.buyPercent)

  const amountToBuy = amountReceived.gt(0)
    ? parsedAmount
        .mul(data.price)
        .mul(data.buyPercent)
        .div(100)
        .div(constants.WeiPerEther)
    : constants.Zero

  const lpMatic = parsedAmount.sub(parsedAmount.mul(data.buyPercent).div(100))

  const lpPtg = parsedAmount
    .mul(100 - data.buyPercent)
    .div(100)
    .mul(data.price)
    .div(constants.WeiPerEther)

  return (
    <>
      <PTGFarm />
      <hr />
      <section className="mt-10">
        <h1 className="text-xl font-bold text-gray-900 mb-10">
          Single sided, with lockup period
        </h1>
        <div>
          <h2>Traunch One. Incentives remaining: ⚔️ (0)</h2>
          <h2>
            Traunch Two. Incentives remaining: $PTG{' '}
            {humanBigNumber(data.contractBalance)}
          </h2>
          <p>Current match percent: {data.matchPercent}%</p>
        </div>
        <div className="mt-4">
          <p>network: {network.chainId} </p>
          <p>your address: {address} </p>
          <p>Your matic balance: {balance && humanBigNumber(balance)}</p>
        </div>

        <div className="mt-8">
          <p>One matic buys {humanBigNumber(data.price || 0)} $PTG</p>
        </div>
        <div className="mt-4 max-w-md">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="amountMatic"
            >
              How much matic to stake?
            </label>
            <input
              type="number"
              step="any"
              max={balance ? humanBigNumber(balance) : 0}
              min={0}
              {...register('amountMatic', { required: true })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {errors.amountMatic && (
              <p className="text-red-600 mb-10">{errors.amountMatic.message}</p>
            )}
            <p>
              You buy: {humanBigNumber(amountToBuy)} PTG and receive
              approximately {humanBigNumber(amountReceived)} $PTG in rewards.
            </p>
            <p>
              You will receive an LP token for approximately{' '}
              {humanBigNumber(lpMatic)} MATIC / {humanBigNumber(lpPtg)} PTG
            </p>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-10"
              type="submit"
            >
              stake
            </button>
          </form>
        </div>
        {data.userTokens.length > 0 && (
          <div className="mt-8">
            <h3>Your version 2 NFTs</h3>
            <table>
              <thead>
                <tr>
                  <th className="px-3">id</th>
                  <th className="px-3">LP Tokens</th>
                  <th className="px-3">deposited on</th>
                  <th className="px-3">available on</th>
                  <th className="px-3">matic value</th>
                  <th className="px-3">ptg value</th>
                </tr>
              </thead>
              <tbody>
                {data.userTokens.map((token, i) => {
                  return (
                    <tr key={`userToken-${token.id.toHexString()}`}>
                      <td className="px-3">{token.id.toHexString()}</td>
                      <td className="px-3">{humanBigNumber(token.amount)}</td>
                      <td className="px-3">
                        {DateTime.fromMillis(
                          token.depositedAt.toNumber() * 1000
                        ).toLocaleString()}
                      </td>
                      <td className="px-3">
                        {DateTime.fromMillis(
                          token.depositedAt.toNumber() * 1000
                        )
                          .plus({ days: 90 })
                          .toLocaleString()}
                      </td>
                      <td>
                        {humanBigNumber((values || [])[i]?.maticAmount || '0')}
                      </td>
                      <td>
                        {humanBigNumber((values || [])[i]?.ptgAmount || '0')}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {data.fonticulusOneTokens.length > 0 && (
          <div className="mt-8">
            <h3>Your version 1 NFTs</h3>
            <table>
              <thead>
                <tr>
                  <th className="px-3">id</th>
                  <th className="px-3">LP Tokens</th>
                  <th className="px-3">deposited on</th>
                  <th className="px-3">available on</th>
                  <th className="px-3">matic value</th>
                  <th className="px-3">ptg value</th>
                </tr>
              </thead>
              <tbody>
                {data.fonticulusOneTokens.map((token, i) => {
                  return (
                    <tr key={`userToken-${token.id.toHexString()}`}>
                      <td className="px-3">{token.id.toHexString()}</td>
                      <td className="px-3">
                        {utils.formatEther(token.amount)}
                      </td>
                      <td className="px-3">
                        {DateTime.fromMillis(
                          token.depositedAt.toNumber() * 1000
                        ).toLocaleString()}
                      </td>
                      <td className="px-3">
                        {DateTime.fromMillis(
                          token.depositedAt.toNumber() * 1000
                        )
                          .plus({ days: 90 })
                          .toLocaleString()}
                      </td>
                      <td>
                        {humanBigNumber(
                          (oneValues || [])[i]?.maticAmount || '0'
                        )}
                      </td>
                      <td>
                        {humanBigNumber((oneValues || [])[i]?.ptgAmount || '0')}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </>
  )
}

export default LiquidityPage
