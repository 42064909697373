import { BigNumber, constants, ContractTransaction } from 'ethers'
import useSWR from 'swr'
import Chain from '../chain'

const useStakingRewards = (chain: Chain) => {
  const { contracts } = chain
  if (!contracts) {
    throw new Error('no contracts')
  }
  const { stakingRewards, pair } = contracts

  const waitThenRevalidate = async (prom:Promise<ContractTransaction>):Promise<any> => {
    const receiptPromise = (await prom).wait()
    receiptPromise.then(revalidate)
    return receiptPromise
  }

  const harvest = async () => {
    return waitThenRevalidate(stakingRewards.getReward())
  }

  const exit = async () => {
    return waitThenRevalidate(stakingRewards.exit())
  }

  const withdraw = async (amount:BigNumber) => {
    return waitThenRevalidate(stakingRewards.withdraw(amount))
  }

  const deposit = async (amount:BigNumber) => {
    return waitThenRevalidate(stakingRewards.stake(amount))
  }

  const approve = async () => {
    return waitThenRevalidate(pair.approve(stakingRewards.address, constants.MaxUint256.sub(1)))
  }

  const { data, revalidate } = useSWR('/staking-rewards', {
    fetcher: async () => {
      const { address } = chain
      console.log('fetching balance for: ', address)
      if (!address) {
        console.log('no address')
        throw new Error('no address')
      }
      const balance = await stakingRewards.balanceOf(address)
      const earned = await stakingRewards.earned(address)
      const isApproved = (await pair.allowance(address, stakingRewards.address)).gt(0)
      console.log('bal: ', balance)
      return {
        balance,
        earned,
        isApproved
      }
    },
    refreshInterval: 5000
  })

  return {
    balance: data?.balance,
    earned: data?.earned,
    isApproved: data?.isApproved,
    revalidate,
    harvest,
    deposit,
    approve,
    withdraw,
    exit
  }
}

export default useStakingRewards
