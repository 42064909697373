import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ChainProvider } from './hooks/useChain'
import Layout from './Layout'
import BuyPage from './pages/BuyPage'
import LiquidityPage from './pages/LiquidityPage'

function App() {
  return (
    <Router>
      <ChainProvider>
        <Layout>
          <Switch>
            <Route path="/buy" exact component={BuyPage} />
            <Route path="/" component={LiquidityPage} />
          </Switch>
        </Layout>
      </ChainProvider>
    </Router>
  )
}

export default App
