function promiseDebounce(
  exec: (...args: any[]) => Promise<any>,
  interval: number
): () => ReturnType<typeof exec> {
  let handle: ReturnType<typeof setTimeout>
  let resolves: Array<(value?: unknown) => void> = []

  return async (...args: unknown[]) => {
    clearTimeout(handle)
    handle = setTimeout(() => {
      const result = exec(...args)
      resolves.forEach((resolve) => resolve(result))
      resolves = []
    }, interval)

    return new Promise((resolve) => resolves.push(resolve))
  }
}

export default promiseDebounce
