import classNames from 'classnames'
import { utils } from 'ethers'
import React, { HTMLAttributes, useCallback, useState } from 'react'
import Chain from '../chain'
import { useChain } from '../hooks/useChain'
import useStakingRewards from '../hooks/useStakingRewards'
import { useLPBalance } from '../hooks/useUniswapPair'
import humanBigNumber from '../utils/humanBigNumber'
import Button from './Button'
import Spinner from './Spinner'

const ApproveButton:React.FC<{chain:Chain}> = ({ chain }) => {
  const { approve } = useStakingRewards(chain)
  const [loading, setLoading] = useState(false)

  const doApprove = useCallback(async () => {
    setLoading(true)
    try {
      await approve()
    } catch (err) {
      console.error(err)
      alert('Something went wrong') // eslint-disable-line no-alert
      throw err
    } finally {
      setLoading(false)
    }
  }, [setLoading, approve])

  if (loading) {
    return <Button loading />
  }

  return (
    <Button onClick={doApprove}>Approve</Button>
  )
}

const HarvestButton:React.FC<{className?:string, chain:Chain}> = ({ chain, className }) => {
  const { harvest } = useStakingRewards(chain)
  const [loading, setLoading] = useState(false)

  const doHarvest = useCallback(async () => {
    setLoading(true)
    try {
      await harvest()
    } catch (err) {
      console.error(err)
      alert('Something went wrong') // eslint-disable-line no-alert
      throw err
    } finally {
      setLoading(false)
    }
  }, [harvest])

  if (loading) {
    return <Button loading />
  }

  return (
    <Button className={className} onClick={doHarvest}>Harvest</Button>
  )
}

const DepositForm:React.FC<HTMLAttributes<{}> & {chain:Chain}> = (userProps) => {
  const { chain, className, ...divProps } = userProps
  const { deposit } = useStakingRewards(chain)
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState('')

  const doDeposit = useCallback(async () => {
    setLoading(true)
    try {
      await deposit(utils.parseEther(amount))
    } catch (err) {
      console.error(err)
      alert('Something went wrong') // eslint-disable-line no-alert
      throw err
    } finally {
      setLoading(false)
      setAmount('')
    }
  }, [deposit, amount])

  if (loading) {
    return (
      <div {...divProps} className={className}><Spinner /> depositing...</div>
    )
  }

  return (
    <div {...divProps} className={classNames('flex flex-col', className)}>
      <input placeholder="Amount to deposit" className="p-2 border-gray-500 rounded-md border mb-4" type="number" step="any" name="amount" value={amount} onChange={(evt) => setAmount(evt.target.value)} />
      <Button onClick={doDeposit}>Deposit</Button>
    </div>
  )
}

const WithdrawForm:React.FC<HTMLAttributes<{}> & {chain:Chain}> = (userProps) => {
  const { chain, className, ...divProps } = userProps
  const { withdraw } = useStakingRewards(chain)
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState('')

  const doWithdraw = useCallback(async () => {
    setLoading(true)
    try {
      await withdraw(utils.parseEther(amount))
    } catch (err) {
      console.error(err)
      alert('Something went wrong') // eslint-disable-line no-alert
      throw err
    } finally {
      setLoading(false)
      setAmount('')
    }
  }, [withdraw, amount])

  if (loading) {
    return (
      <div {...divProps} className={className}><Spinner /> withdrawing...</div>
    )
  }

  return (
    <div {...divProps} className={classNames('flex flex-col', className)}>
      <input placeholder="Amount to withdraw" className="p-2 border-gray-500 rounded-md border mb-4" type="number" step="any" name="amount" value={amount} onChange={(evt) => setAmount(evt.target.value)} />
      <Button onClick={doWithdraw}>Withdraw</Button>
    </div>
  )
}

const PTGFarm: React.FC = () => {
  const { chain } = useChain()
  const lpBalance = useLPBalance(chain)
  const { balance, earned, isApproved } = useStakingRewards(chain)

  return (
    <section className="mb-24">
      <h1 className="text-xl font-bold text-gray-900">
        Matic / wPTG Farm
      </h1>
      <p className="my-4">Normal staking rewards using the SNX staking rewarder. Quick Swap Pair: <a className="underline text-blue-800" href="https://info.quickswap.exchange/pair/0xEc78d09aFBee1Ee3a8D3446aD0D5600d62C21eB3">0xEc78d09aFBee1Ee3a8D3446aD0D5600d62C21eB3</a></p>
      <div className="flex-row flex justify-evenly text-center">
        <div className="flex-col p-24 border border-gray-75 rounded-md">
          <h2>Balance</h2>
          <p>{humanBigNumber(lpBalance || 0)}</p>
          {!isApproved && (
            <ApproveButton chain={chain} />
          )}
          {isApproved && (
            <DepositForm className="mt-4" chain={chain} />
          )}
        </div>
        <div className="p-24 border border-gray-75 rounded-md">
          <h2>Deposited</h2>
          <p>{humanBigNumber(balance || 0)}</p>
          <WithdrawForm chain={chain} className="mt-4" />
        </div>
        <div className="p-24 border border-gray-75 rounded-md">
          <h2>Earned</h2>
          <p>{humanBigNumber(earned || 0, 10)}</p>
          <HarvestButton className="mt-4" chain={chain} />
        </div>
      </div>
    </section>
  )
}
export default PTGFarm
