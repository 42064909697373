import { Token } from '@uniswap/sdk'
import { BigNumber, BigNumberish } from 'ethers'
import { useCallback } from 'react'
import useSWR from 'swr'
import Chain, { wrappedMaticAsset, wrappedPTGAddress } from '../chain'

const getReserves = async (chain: Chain) => {
  const { contracts, networkName } = chain
  if (!contracts) {
    throw new Error('no contracts to get reserves')
  }
  const { pair } = contracts

  const wMatic = new Token(137, wrappedMaticAsset(networkName), 18)
  const wPTG = new Token(137, wrappedPTGAddress(networkName), 18)
  const tokens = [wMatic, wPTG]
  const [token0] = tokens[0].sortsBefore(tokens[1])
    ? tokens
    : [tokens[1], tokens[0]]

  const [reserve0, reserve1] = await pair.getReserves()
  const [inReserve, outReserve] = token0.equals(wMatic)
    ? [reserve0, reserve1]
    : [reserve1, reserve0]
  return [inReserve, outReserve]
}

export const useLPBalance = (chain: Chain) => {
  const { data: lpBalance } = useSWR('/lp-balance', {
    fetcher: async () => {
      const { contracts, address } = chain
      if (!contracts) {
        throw new Error('no contracts')
      }
      if (!address) {
        throw new Error('no address')
      }
      const { pair } = contracts
      console.log('fetch balance of', address)
      return pair.balanceOf(address)
    },
  })

  return lpBalance
}

const useUniswapPair = (chain: Chain) => {
  const { contracts } = chain

  const swap = useCallback(
    async (inAmount: BigNumberish, expectedOut: BigNumber, to: string) => {
      if (BigNumber.from(inAmount).eq(0)) {
        throw new Error('no swapping for 0')
      }
      if (!contracts) {
        throw new Error('no contracts in getOutput')
      }
      const { uniswapRouter, wrappedPTG, wrappedMatic } = contracts

      return uniswapRouter.swapExactETHForTokens(
        expectedOut,
        [wrappedMatic.address, wrappedPTG.address],
        to,
        BigNumber.from(Math.floor(new Date().getTime() / 1000 + 100)),
        {
          value: inAmount
        }
      )
    },
    [contracts]
  )

  const getOutput = useCallback(
    async (inAmount: BigNumberish) => {
      try {
        if (BigNumber.from(inAmount).eq(0)) {
          return BigNumber.from('0')
        }
        if (!contracts) {
          throw new Error('no contracts in getOutput')
        }
        const { uniswapRouter } = contracts

        const [inReserve, outReserve] = await getReserves(chain)

        return await uniswapRouter.getAmountOut(inAmount, inReserve, outReserve)
      } catch (err) {
        console.error('err in get output: ', err)
        throw err
      }
    },
    [contracts, chain]
  )

  return {
    swap,
    getOutput,
  }
}

export default useUniswapPair
