import { providers } from 'ethers'
import EventEmitter from 'events'
import Web3Modal from 'web3modal'
import Torus from '@toruslabs/torus-embed'
import maticQSRewardsDeploy from 'crypto-colosseum-staking-rewards/deployments/matic/QSPTGMaticPoolRewarder.json'
import { StakingRewards, StakingRewards__factory } from 'crypto-colosseum-staking-rewards/dist/types/ethers-contracts'
import {
  FonticulusTraunchOne,
  FonticulusTraunchOne__factory,
  FonticulusTraunchTwo,
  FonticulusTraunchTwo__factory,
  IERC20,
  IERC20__factory,
  IUniswapV2Factory,
  IUniswapV2Factory__factory,
  IUniswapV2Pair,
  IUniswapV2Pair__factory,
  IUniswapV2Router02,
  IUniswapV2Router02__factory
} from './types/ethers-contracts'

export type KnownNetworkNames = 'mumbai' | 'matic'

export const networkNames: Record<number, string> = {
  137: 'Matic Mainnet',
  80001: 'Matic Mumbai Network'
}

function stakingRewardsAddress(networkName: string) {
  switch (networkName) {
    case 'matic':
      return maticQSRewardsDeploy.address
    case 'mumbai':
      return maticQSRewardsDeploy.address // this is bad - this address doesn't exist - won't work on mumbai
    default:
      return undefined
  }
}

async function uniswapRouterAddress(networkName: string) {
  switch (networkName) {
    case 'mumbai':
      return (await getDeployment(networkName, 'UniswapV2Router02')).address
    case 'matic':
      return '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff'
    default:
      throw new Error('unknown network name')
  }
}

export function wrappedPTGAddress(networkName: string) {
  switch (networkName) {
    case 'mumbai':
      return '0x102E3A9Fa4F7Ab33aeD5E00b0bbC450918aA8b21'
    case 'matic':
      return '0xc0f14C88250E680eCd70224B7fBa82b7C6560d12'
    default:
      throw new Error('unknown network name')
  }
}

export function wrappedMaticAsset(networkName: string) {
  switch (networkName) {
    case 'mumbai':
      return '0xBBC3b1B4CCD5FDB5832118b146276e70590a62a7'
    case 'matic':
      return '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
    default:
      throw new Error('unknown network name')
  }
}

const chainIds: Record<string, number> = {
  mumbai: 80001,
  matic: 137
}

const networkName = (new URLSearchParams(window.location.search).get(
  'network'
) || 'matic') as KnownNetworkNames

console.log('chainids: ', chainIds[networkName])

const providerOptions = {
  torus: {
    package: Torus // required
  }
}

const web3Modal = new Web3Modal({
  network: networkName, // optional
  cacheProvider: true, // optional
  providerOptions // required
})

async function getDeployment(networkName: string, contractName: string) {
  const abi = await import(`./deployments/${networkName}/${contractName}.json`)
  return abi
}

interface Contracts {
  fonticulus: FonticulusTraunchOne
  fonticulusTwo: FonticulusTraunchTwo
  uniswapRouter: IUniswapV2Router02
  uniswapFactory: IUniswapV2Factory
  pair: IUniswapV2Pair,
  wrappedPTG: IERC20,
  wrappedMatic: IERC20,
  stakingRewards: StakingRewards
}

class Chain extends EventEmitter {
  provider?: providers.Web3Provider

  signer?: providers.JsonRpcSigner

  connected = false

  address?: string

  contracts?: Contracts

  network?: providers.Network

  networkName: KnownNetworkNames

  constructor(networkName: KnownNetworkNames) {
    super()
    this.networkName = networkName
  }

  async connect() {
    const web3Provider = await web3Modal.connect()
    web3Provider.on('networkChanged', async () => {
      console.log('web3 provider network change')
      // window.location.reload()
    })
    web3Provider.on('accountsChanged', async (info: any) => {
      console.log('web3 provider account change', info)
      // window.location.reload()
    })
    this.provider = new providers.Web3Provider(web3Provider)

    console.log('provider: ', this.provider)
    this.network = await this.provider.getNetwork()
    this.signer = this.provider.getSigner()
    this.address = await this.signer.getAddress()
    this.connected = true

    if (this.network.chainId !== this.expectedChain()) {
      this.emit('connect')
      return
    }

    const fonticulusAddress = (
      await getDeployment(this.networkName, 'FonticulusTraunchOne')
    ).address
    const fonticulus2Address = (
      await getDeployment(this.networkName, 'FonticulusTraunchTwo')
    ).address

    const uniswapRouter = IUniswapV2Router02__factory.connect(
      await uniswapRouterAddress(this.networkName),
      this.signer
    )
    const factoryAddr = await uniswapRouter.factory()
    const uniswapFactory = IUniswapV2Factory__factory.connect(
      factoryAddr,
      this.signer
    )

    const wrappedPTG = IERC20__factory.connect(wrappedPTGAddress(this.networkName), this.signer)
    const wrappedMatic = IERC20__factory.connect(wrappedMaticAsset(this.networkName), this.signer)

    const pairAddr = await uniswapFactory.getPair(
      wrappedPTG.address,
      wrappedMatic.address,
    )
    const pair = IUniswapV2Pair__factory.connect(pairAddr, this.signer)

    const stakingRewards = StakingRewards__factory.connect(stakingRewardsAddress(this.networkName)!, this.signer)

    this.contracts = {
      fonticulus: FonticulusTraunchOne__factory.connect(
        fonticulusAddress,
        this.signer
      ),
      fonticulusTwo: FonticulusTraunchTwo__factory.connect(
        fonticulus2Address,
        this.signer
      ),
      uniswapRouter,
      uniswapFactory,
      pair,
      wrappedPTG,
      wrappedMatic,
      stakingRewards,
    }

    this.emit('connect')
  }

  expectedChain() {
    return chainIds[this.networkName]
  }
}

export default Chain
