import React, { ButtonHTMLAttributes } from 'react'
import classNames from 'classnames'
import Spinner from './Spinner'

const Button:React.FC<ButtonHTMLAttributes<{}> & {loading?:boolean}> = (userProps) => {
  const { children, className, loading, ...buttonProps } = userProps

  return (
    <button {...buttonProps} className={classNames('py-2 px-4 text-center rounded-md bg-green-800 text-white', className)}>
      {loading && <Spinner />}
      {!loading && children}
    </button>
  )
}

export default Button
