import { BigNumber, utils } from 'ethers'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useChain } from '../hooks/useChain'
import { useMaticBalance } from '../hooks/useMaticBalance'
import useUniswapPair from '../hooks/useUniswapPair'
import debounce from '../debounce'

interface BuyPageFormData {
  amountMatic: string
  sendTo: string
}

const BuyPage: React.FC = () => {
  const { chain } = useChain()
  const { contracts } = chain

  const [swapping, setSwapping] = useState(false)

  const { register, handleSubmit, watch } = useForm<BuyPageFormData>()
  const { balance } = useMaticBalance(chain)
  const { getOutput, swap } = useUniswapPair(chain)

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedOutput = useCallback(
    debounce(getOutput, 200) as typeof getOutput,
    [getOutput]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const [output, setOutput] = useState(BigNumber.from('0'))

  const onSubmit = async ({ amountMatic, sendTo }: BuyPageFormData) => {
    console.log('swapping ', amountMatic, ' for ', amountSet, ' to: ', sendTo)
    setSwapping(true)
    const tx = await swap(utils.parseEther(amountMatic), output, sendTo)
    const receipt = await tx.wait()
    console.log('tx receipt: ', receipt)
    setSwapping(false)
  }

  const amountSet = watch('amountMatic') || '0'
  console.log('amountSet: ', amountSet)

  useEffect(() => {
    if (!contracts) {
      return
    }
    const amountReceived = utils.parseEther(amountSet)
    console.log('set: ', amountSet)

    debouncedOutput(amountReceived).then((amt) => {
      console.log('setting: ', amt)
      setOutput(amt)
    })
  }, [amountSet, setOutput, debouncedOutput, contracts])

  if (swapping) {
    return (
      <>
        <p>Swapping...</p>
      </>
    )
  }

  return (
    <>
      <h2 className="text-2xl mb-10">Buy wPTG</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="amountMatic"
        >
          Matic
        </label>
        <input
          type="number"
          step="any"
          max={balance?.mul(100).div(99) ? utils.formatEther(balance) : 0}
          min={0}
          {...register('amountMatic', { required: true })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <p>Gets you: {utils.formatEther(output)} $PTG</p>
        <label
          className="block text-gray-700 text-sm font-bold mb-2 mt-5"
          htmlFor="sendTo"
        >
          Send it to
        </label>
        <input
          type="text"
          {...register('sendTo', { required: true })}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          className="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          type="submit"
        >
          Buy
        </button>
      </form>
    </>
  )
}

export default BuyPage
