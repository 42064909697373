import useSWR from 'swr'
import Chain from '../chain'

export const useMaticBalance = (chain: Chain) => {
  const { signer, address } = chain

  const { data, isValidating } = useSWR(
    () => {
      if (!signer) {
        throw new Error('waiting on signer')
      }
      return ['/matic-balance', address]
    },
    {
      fetcher: () => {
        return signer?.getBalance()
      }
    }
  )

  return {
    balance: data,
    loading: !data,
    updating: isValidating
  }
}
