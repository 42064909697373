import { BigNumber, constants } from 'ethers'
import useSwr from 'swr'
import Chain from '../chain'
import { IERC20__factory } from '../types/ethers-contracts'

const wrappedPTGAddresses: Record<number, string> = {
  80001: '0x102E3A9Fa4F7Ab33aeD5E00b0bbC450918aA8b21',
  137: '0xc0f14C88250E680eCd70224B7fBa82b7C6560d12'
}

export const useFonticulus = (chain: Chain) => {
  const { contracts, network, address, provider } = chain
  const { fonticulusTwo: fonticulus, fonticulus: fonticulusOne } = contracts || {}

  const stake = async (
    amountMatic: BigNumber,
    minPTG: BigNumber,
    deadline: BigNumber
  ) => {
    if (!fonticulus) {
      throw new Error('no contract')
    }

    const txP = fonticulus.addLiquidityMATIC(minPTG, deadline, {
      value: amountMatic
    })
    txP.then(async (tx) => {
      await tx.wait()
      revalidate()
    })
    return txP
  }

  const { data, isValidating, revalidate } = useSwr(
    () => {
      if (!fonticulus || !network) {
        console.log('waiting on contract')
        throw new Error('waiting on network or contract')
      }
      if (network.chainId !== chain.expectedChain()) {
        throw new Error('waiting on chainId')
      }
      console.log('here')
      return '/fonticulus'
    },
    {
      fetcher: async () => {
        console.log('fetching: ', fonticulus)
        try {
          if (!fonticulus || !fonticulusOne) {
            throw new Error('no contract')
          }
          if (!address) {
            throw new Error('no address')
          }
          if (!network) {
            throw new Error('no network')
          }

          const wrappedPTG = IERC20__factory.connect(
            wrappedPTGAddresses[network.chainId],
            provider!
          )

          const contractBalance = await wrappedPTG.balanceOf(fonticulus.address)
          console.log('wrapped ptg balance: ', contractBalance.toString())

          const price = await fonticulus.price(constants.WeiPerEther)
          console.log('price: ', price)

          const matchPercent = 100 - (((100000 - (await fonticulus.percentMatched())) * 2) / 1000)
          const buyPercent = ((100000 - (await fonticulus.percentMatched()))) / 1000

          const userTokenIds = await fonticulus.userTokens(address)
          const userTokens = await Promise.all(
            userTokenIds.map(async (id) => {
              const token = await fonticulus.tokens(id)
              return {
                ...token,
                id
              }
            })
          )

          const fonticulusOneTokenIds = await fonticulusOne.userTokens(address)
          const fonticulusOneTokens = await Promise.all(
            fonticulusOneTokenIds.map(async (id) => {
              const token = await fonticulusOne.tokens(id)
              return {
                ...token,
                id
              }
            })
          )

          return {
            contractBalance,
            price,
            userTokens,
            fonticulusOneTokens,
            matchPercent,
            buyPercent,
          }
        } catch (err) {
          console.error('error getting fonticulus: ', err)
          throw err
        }
      }
    }
  )

  return {
    data,
    isValidating,
    revalidate,
    stake
  }
}
