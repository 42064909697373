import { useLocation } from 'react-router-dom'
import { KnownNetworkNames } from '../chain'

const useNetworkName = () => {
  const { search } = useLocation()
  const networkName = (new URLSearchParams(search).get('network') ||
    'matic') as KnownNetworkNames
  if (!networkName || !['mumbai', 'matic'].includes(networkName)) {
    throw new Error(`Unknown network: ${networkName}`)
  }
  return networkName
}

export default useNetworkName
