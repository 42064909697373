import { BigNumber, BigNumberish, utils } from 'ethers'

const humanBigNumber = (num: BigNumber | BigNumberish, digits:number = 3) => {
  const formatter = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: 2,
  })

  const formatted = utils.formatEther(num)
  const number = parseFloat(formatted)
  return formatter.format(number)
}

export default humanBigNumber
