import { Pair, Token, TokenAmount } from '@uniswap/sdk'
import { BigNumber } from 'ethers'
import useSWR from 'swr'
import Chain, { wrappedMaticAsset, wrappedPTGAddress } from '../chain'

interface FonticulusToken {
  amount: BigNumber
}

const fetcher = async (
  _: string,
  chain: Chain,
  fonticulusTokens: FonticulusToken[]
) => {
  try {
    const { contracts, networkName } = chain

    if (!contracts) {
      throw new Error('no contracts in getOutput')
    }

    const { pair } = contracts

    const wMatic = new Token(137, wrappedMaticAsset(networkName), 18)
    const wPTG = new Token(137, wrappedPTGAddress(networkName), 18)
    const tokens = [wMatic, wPTG]
    const [token0, token1] = tokens[0].sortsBefore(tokens[1])
      ? tokens
      : [tokens[1], tokens[0]]

    const totalSupply = await pair.totalSupply()
    const klast = await pair.kLast()

    const [reserve0, reserve1] = await pair.getReserves()
    const uniswapSdkPair = new Pair(
      new TokenAmount(token0, reserve0.toHexString()),
      new TokenAmount(token1, reserve1.toHexString())
    )

    return fonticulusTokens.map((token) => {
      const lpTokens = token.amount

      const maticAmount = uniswapSdkPair.getLiquidityValue(
        wMatic,
        new TokenAmount(uniswapSdkPair.liquidityToken, totalSupply.toHexString()),
        new TokenAmount(uniswapSdkPair.liquidityToken, lpTokens.toHexString()),
        true,
        klast.toHexString()
      )
      const ptgAmount = uniswapSdkPair.getLiquidityValue(
        wPTG,
        new TokenAmount(uniswapSdkPair.liquidityToken, totalSupply.toHexString()),
        new TokenAmount(uniswapSdkPair.liquidityToken, lpTokens.toHexString()),
        true,
        klast.toHexString()
      )

      return {
        maticAmount: BigNumber.from(maticAmount.raw.toString()),
        ptgAmount: BigNumber.from(ptgAmount.raw.toString())
      }
    })
  } catch (err) {
    console.error('error fetching values: ', err)
    throw err
  }
}

const useTokenValue = (chain: Chain, tokens?: FonticulusToken[]) => {
  const { data, isValidating } = useSWR(
    () => {
      if (!chain || !tokens) {
        throw new Error('no LP Values')
      }
      return ['/lpValues', chain, tokens]
    },
    {
      fetcher
    }
  )

  return {
    values: data,
    loading: !data,
    isValidating
  }
}

export default useTokenValue
